<template>
  <v-card elevation="6">
    <v-card-title
      ><h2
        class="my-3"
        style="text-transform: uppercase; font-weight: normal"
        v-text="$tc('product')"
      ></h2>
      <InventoryEdit @update="$emit('update')"></InventoryEdit>
    </v-card-title>
    <v-divider> </v-divider>
    <v-simple-table class="pb-5">
      <tr>
        <td>{{ $t("product.description") }}</td>
        <td>{{ inventory.description }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.purchase_price") }}</td>
        <td>{{ $n(inventory.product_price, "currency") }}</td>
      </tr>

      <tr>
        <td>{{ $t("inventorys.sale_price") }}</td>
        <td>{{ $n(inventory.sale_price, "currency") }}</td>
      </tr>

      <tr>
        <td>{{ $t("brand") }}</td>
        <td v-if="inventory.brand != null">{{ inventory.brand.brand_name }}</td>
        <td v-else>-</td>
      </tr>

      <tr>
        <td>{{ $t("category") }}</td>
        <td v-if="inventory.category != null">
          {{ inventory.category.category_name }}
        </td>
        <td v-else>-</td>
      </tr>
      <tr v-if="inventory.url">
        <td>{{ $t("inventorys.add_file") }}</td>
        <td>
          <PhotoGallery
            class="ml-n2"
            :photos="[inventory.url]"
            numOfCols="4"
            title="Imagen del producto"
          />
        </td>
      </tr>
    </v-simple-table>

    <v-img v-if="false" :src="inventory.url" height="250px"></v-img>
  </v-card>
</template>

<script>
export default {
  name: "InventoryCard",
  props: ["inventory"],
  components: {
    InventoryEdit: () => import("@/views/inventory/InventoryEdit"),
    PhotoGallery: () => import("@/components/ui/ImageGalery"),
  },
};
</script>

<style scoped lang="sass">
.v-data-table
  td
    padding: 3px 8px !important
    vertical-align: top
    &:first-child
      color: var(--v-gris1-base)

h2
  font-weight: normal
  font-size: 20px
</style>
